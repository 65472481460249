// PrivateRoute.js
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useAuth } from "../Hooks/AuthContext";

const PrivateRoute = ({ routes }) => {
  const { isAuthenticated, loading, checkAuthStatus } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        await checkAuthStatus();
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    if (!loading) {
      checkAuthentication();
    }
  }, [checkAuthStatus, loading]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          isAuthenticated ? (
            routes
          ) : (
            <Navigate
              to="/login"
              state={{ intendedDestination: location.pathname }}
            />
          )
        }
      />
    </Routes>
  );
};

export default PrivateRoute;
